import $ from 'jquery';
//
// import "scrollmagic";
//
window.ScrollMagic = require("scrollmagic");

var controller = new ScrollMagic.Controller();


$(function () { // wait for document ready
	// init
	var controller = new ScrollMagic.Controller({
		globalSceneOptions: {
			triggerHook: 'onEnter'
		}
	});


		var items_tween_1 = new TimelineMax ()
			.add([
				TweenMax.fromTo(".magic-scroll-slsc", 1, {marginTop: "-100%"}, {marginTop: "0%", ease: Power3.easeOut})
			]);

			if (window.matchMedia("(min-width: 1025px)").matches) {
				var items_scene = new ScrollMagic.Scene({triggerElement: "#introduction-bg", duration: "400%"})
					.setTween(items_tween_1)
					// .addIndicators({name: "items 2 big"}) // add indicators (requires plugin)
					.addTo(controller);
			} else {
				var items_scene = new ScrollMagic.Scene({triggerElement: "#introduction-bg", duration: "400%"})
					.setTween(items_tween_1)
					// .addIndicators({name: "items 2"}) // add indicators (requires plugin)
					.addTo(controller);
			}

			// var items_tween_2 = new TimelineMax ()
			// 	.add([
			// 		TweenMax.fromTo(".team-members-alternate .image-wrapper", 1, {marginTop: "-100%"}, {marginTop: "0%", ease: Power3.easeOut})
			// 	]);
			//
			// 	if (window.matchMedia("(min-width: 1025px)").matches) {
			// 		var items_scene = new ScrollMagic.Scene({triggerElement: ".team-members-alternate", duration: "300%"})
			// 			.setTween(items_tween_2)
			// 			// .addIndicators({name: "items 2 big"}) // add indicators (requires plugin)
			// 			.addTo(controller);
			// 	} else {
			// 		var items_scene = new ScrollMagic.Scene({triggerElement: ".team-members-alternate", duration: "300%"})
			// 			.setTween(items_tween_2)
			// 			// .addIndicators({name: "items 2"}) // add indicators (requires plugin)
			// 			.addTo(controller);
			// 	}

			var items_tween_2 = function(teamMemberContainerElement) {
				return new TimelineMax ()
				.add([
					TweenMax.fromTo(teamMemberContainerElement.querySelector(".image-wrapper"), 1, {marginTop: "100%"}, {marginTop: "0%", ease: Power3.easeOut})
				]);
			}

				$('.team-members-alternate').each(function(){
					if (this.querySelector(".image-wrapper")) {
						// build scene
						var headshots = new ScrollMagic.Scene({ triggerElement: this, duration: "100%"})
								.setTween(items_tween_2(this))
								// .addIndicators( {name: "headshots"} )
								.addTo(controller);
					}
        	});



});

// fallback: show controls if autoplay fails
// (needed for Samsung Internet for Android, as of v6.4)
// window.addEventListener('load', async () => {
//   let video = document.querySelector('video[muted][autoplay]');
//   try {
//     await video.play();
//   } catch (err) {
//     video.controls = true;
//   }
// });
